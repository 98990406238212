<template>
  <ServiceLayout>
    <div class="md-service-1">
      <!-- 广告图 -->
      <Banner :list="bannerList" height="4.92rem" v-ani.fade-up />
      <!-- 导航 -->
      <ServiceNav :list='nav' @change="jump" v-ani.fade-up />
      <!-- 钢铁计算 -->
      <ServiceForm id="section_1" title="钢铁计算" />
      <!-- 建筑设计规范查询 -->
      <ServiceDownload id="section_2" :title="toolArr[0]?.title" :cate='toolArr[0]?.cat_list' :list="zeroArr"
        @change="zeroChange" />
      <!-- 各种组织方案编号 -->
      <ServiceDownload id="section_3" :title="toolArr[1]?.title" :cate='toolArr[1]?.cat_list' :list="oneArr"
        @change="oneChange" />
      <!-- 施工指导教程 -->
      <ServiceDownload id="section_4" :title="toolArr[2]?.title" :cate='toolArr[2]?.cat_list' :list="twoArr"
        @change="twoChange" />
    </div>
  </ServiceLayout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
import { QueriesToolBaseApi, QueriesToolListApi } from "@/request/api/service";
export default {
  created() {
    // 轮播图
    IndexBannerApi({ from: 425 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
    QueriesToolBaseApi().then(({ status, data }) => {
      if (status == 200) {
        this.toolArr = data;
      }
    })
    this.zeroFn();
    this.oneFn();
    this.twoFn();
  },
  data() {
    return {
      bannerList: [],
      toolArr: [],
      zeroArr: [],
      oneArr: [],
      twoArr: [],
      zeroData: {
        id: 1,
        cat_id: 0,
      },
      oneData: {
        id: 2,
        cat_id: 0,
      },
      twoData: {
        id: 3,
        cat_id: 0,
      },
      nav: [
        { id: 1, title: '钢铁计算' },
        { id: 2, title: '建筑设计规范查询' },
        { id: 3, title: '各种组织方案编号' },
        { id: 4, title: '施工指导教程' },
      ],
      cateList: [
        { id: '', title: '全部' },
        { id: 1, title: '20套建筑工程钢筋施工方案' },
        { id: 2, title: '20套建筑工程后浇带施工方案' },
        { id: 3, title: '20套建筑工程混凝土施工方案合集' },
        { id: 4, title: '20套建筑工程模板施工方案合集' },
        { id: 5, title: '40套建筑分部分项施工方案' },
      ],
      cateList2: [
        { id: '', title: '全部' },
        { id: 1, title: '模板工施工实训' },
        { id: 2, title: '20套建筑工程钢筋施工方案' },
        { id: 3, title: '钢筋工施工实训' },
        { id: 4, title: '砌筑工施工实训' },
        { id: 5, title: '架子工施工实训' },
      ]
    };
  },
  methods: {
    jump(id) {
      let dom = document.getElementById('section_' + id)
      window.scroll({
        top: dom.offsetTop - 100,
        behavior: "smooth",
      })
    },
    zeroFn() {
      QueriesToolListApi(this.zeroData).then(({ status, data }) => {
        if (status == 200) {
          this.zeroArr = data;
        }
      })
    },
    zeroChange(e) {
      this.zeroData.cat_id = e;
      this.zeroFn();
    },
    oneFn() {
      QueriesToolListApi(this.oneData).then(({ status, data }) => {
        if (status == 200) {
          this.oneArr = data;
        }
      })
    },
    oneChange(e) {
      this.oneData.cat_id = e;
      this.oneFn();
    },
    twoFn() {
      QueriesToolListApi(this.twoData).then(({ status, data }) => {
        if (status == 200) {
          this.twoArr = data;
        }
      })
    },
    twoChange(e) {
      this.twoData.cat_id = e;
      this.twoFn();
    }
  }
};
</script>

<style lang="scss" scoped>
.md-service-nav {
  display: flex;
  flex-wrap: wrap;
  padding: .1rem .3rem;
  margin-top: .4rem;

  .item {
    color: $blue;
    background: $blueLight;
    border-radius: .04rem;
    padding: 0 .2rem;
    margin: .2rem .2rem .2rem 0;
    line-height: .4rem;
    transition: .3s;

    &:last-of-type {
      margin-right: 0;
    }

    &.on,
    &:hover {
      background: $blue;
      color: #fff;
    }
  }
}
</style>